<template>
  <Anonymous>
    <b-overlay rounded="sm">

      <div class="row my-3">
        <div class="col logo text-center">
          <BigLogo />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3 class="text-center">You stopped watching notifications</h3>
        </div>
      </div>

    </b-overlay>

  </Anonymous>
</template>

<script>
  import Anonymous from "../layouts/Anonymous";
  import BigLogo from "../components/BigLogo";

  export default {
    name: 'SubscriptionChanged',
    components: {
      Anonymous, BigLogo
    }
  }

</script>
